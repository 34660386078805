import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";

const Footer = () => {
  return (
    <div className="footer">
      <img className="yellowimage" src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <span style={{fontSize:'45px'}}>luelteshome92@gmail.com</span>
      </div>
    </div>
  );
};

export default Footer;
