import React, { useContext, useRef, useState } from "react";
import "./Contact.css";
import { themeContext } from "../../Context";
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const Main=()=>{

  let Result=()=>{
    toast.success('Cheers!! Got your info, will contact you soon!', 
    {position: toast.POSITION.TOP_RIGHT})
  }

  const form = useRef();

  const Sendemail=(e)=>{
    e.preventDefault();
  
    emailjs.sendForm('service_xmch9ln', 'template_rtexzhv', form.current, 'yTp98MGWpVO1qqXnx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return(
    <form ref={form} onSubmit={Sendemail}>
    <input 
    type="text"
     name="name" 
     className="user" 
      placeholder="Name"
      />
    <input 
    type="email" 
    name="email" 
    className="user" 
    placeholder="Email"
    />
    <input 
    type="text" 
    name="business" 
    className="user"
     placeholder="business type"
     />
    <textarea
     name="message" 
     className="user"
      placeholder="Message"
      />
    <button onClick={Result} id="buttonn">Let's Go!</button>
    <ToastContainer theme="dark" />
    <div
      className="blur c-blur1"
      style={{ background: "#1e7d8c" }}
    ></div>
  </form>
  )
}



const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const form = useRef();

  const Sendemail=(e)=>{
    e.preventDefault();
  
    emailjs.sendForm('service_xmch9ln', 'template_rtexzhv', form.current, 'yTp98MGWpVO1qqXnx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <div className="contact-form" id="contact">
      {/* left side copy and paste from work section */}
      <div className="w-left">
        <div className="awesome">
          {/* darkMode */}
          <span style={{color: darkMode?'white': ''}}>Get in Touch</span> 
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>
      </div>
      {/* right side form */}
      <div className="c-right">
       <Main />
      </div>
    </div>
  );
};

export default Contact;
